<template>
    <v-container fluid tag="section">

        <div class="text-end">
            <v-btn color="purple" class="font-weight-bold mr-0" :to="`/users/add`">Add user</v-btn>
        </div>

        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg mt-2">
                    <v-card-title>
                        <v-row class="py-2">
                            <v-col cols="12" md="9">
                                <v-btn outlined class="font-weight-bold me-2">
                                    {{ $t('Filter') }} <v-icon right>mdi-filter</v-icon>
                                </v-btn>
                                <v-btn outlined class="font-weight-bold me-2">
                                    {{ $t('Export') }} <v-icon right>mdi-file-table</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="3" class="text-md-end">
                                <v-text-field hide-details flat filled outlined solo dense single-line
                                    append-icon="mdi-magnify" :label="$t('Search')" v-model="search"
                                    @keyup.enter="onSearch"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="px-0 py-0">
                        <v-data-table :items="items" :headers="headers" :options.sync="options"
                            :server-items-length="totalItems" :loading="loading" class="elevation-1">
                            <template v-slot:item="row">
                                <tr>
                                    <td>{{ row.item.id }}</td>
                                    <td>{{ row.item.username }}</td>
                                    <td>{{ row.item.email }}</td>
                                    <td>{{ row.item.role?.name }}</td>
                                    <td>
                                        <router-link :to="`/companies/edit/${row.item.company?.id}`">
                                            {{ row.item.company?.name }}
                                        </router-link>
                                    </td>
                                    <td>{{ row.item.created_at | utcToLocal }}</td>
                                    <td>
                                        <v-chip small color="success" class="font-weight-bold" v-if="row.item.account_status === 'active'">
                                            {{ $t('Active') }}
                                        </v-chip>
                                        <v-chip small class="font-weight-bold" v-if="row.item.account_status !== 'active'">
                                            {{ $t(row.item.account_status.toUpperCase()) }}
                                        </v-chip>
                                    </td>
                                    <td>
                                        <v-btn small color="error" class="mx-2" @click="onDelete(row.item)">
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                        <v-btn small color="blue" class="mx-2" :to="`/users/edit/${row.item.id}`">
                                            <v-icon>
                                                mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import userService from '@/services/userService'

export default {
    data() {
        return {
            items: [],
            totalItems: 0,
            loading: true,
            options: { sortBy: ['id'] },
            search: null,
            headers: [
                { text: '#', value: 'id' },
                { text: 'Username', value: 'username' },
                { text: 'Email', value: 'email' },
                { text: 'Role', value: 'role_name' },
                { text: 'Company', value: 'company_name' },
                { text: 'Created', value: 'created_at' },
                { text: 'Status', value: 'account_status' },
                { text: 'Action', sortable: false },
            ]
        }
    },
    watch: {
        options: {
            handler(curr, prev) {
                const watchProps = ['page', 'itemsPerPage', 'sortBy', 'sortDesc']
                for (let i = 0; i < watchProps.length; i++) {
                    const key = watchProps[i]
                    if (prev[key] !== curr[key]) {
                        return this.getusers()
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        async getusers() {
            this.loading = true
            try {
                const result = await userService.paginate({ 
                    ...this.options,
                    search: this.search,
                    include_locations: true,
                    include_company: true,
                    include_role: true
                })
                this.items = result.data.data
                this.totalItems = result.data.total
                this.options.page = result.data.page
            } catch (error) {
                this.$dialog.message.error(error.message)
            } finally {
                this.loading = false
            }
        },
        onSearch() {
            this.getusers()
        },
        async onDelete(item) {
            const confirm = await this.$dialog.error({
                title: this.$t('Warning'),
                text: this.$t('Are you sure you want to delete this item?'),
            })
            if (confirm) {
                try {
                    await userService.delete(item.id)
                    this.getusers()
                    this.$dialog.message.success(this.$t('Success'))
                } catch (error) {
                    this.$dialog.message.error(error.message)
                }
            }
        },
    },
}
</script>